<template>
  <div>
    <v-btn outlined @click="EditingSingleView = !EditingSingleView">{{EditingSingleView ? 'Cancel' : 'Edit'}}
    </v-btn>
     <LandingPageSingle v-if="!EditingSingleView && EntityReady" :DataSingleTabs="ComputedSingleBuildTabs"
       :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
       :SinglePageBGGradient="SinglePageBGGradient" @ActivateProcessing="ActivateProcessing" :SystemPhotoLibraries="SystemPhotoLibraries"
       :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"
       @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
       :EntityDataRef="OperationalDB" @onPhotoUpload="onPhotoUpload" @GetShortLivedURL="GetShortLivedURL"
       :EntityCollectionRef="EntityCollectionRef" :CurrentEntity="CurrentEntity"
       :System="System" :SystemEntities="SystemEntities"  :AdditionalSaveMethod="''" @UpdateRecordCalculation="UpdateRecordCalculation"
        @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
        :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit" :tab="tab"
        :DynamicWikiData="DynamicRecordData" :Business_Unit="Business_Unit"
        :RelatedObj="RecordData" :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
        :ActivitiesArray="ActivitiesArray" :FeatureNavList="FeatureNavList"
        :PrimaryFieldName="CurrentEntity.PrimaryFieldName" :clientDB="clientDB"
       :ActiveEntityID="ConfigEntityID"/>
       
       <NewLandingPage v-if="EditingSingleView" :DataSingleTabs="ComputedSingleBuildTabs"
       :SinglePageBGGradient="SinglePageBGGradient" :SystemPhotoLibraries="SystemPhotoLibraries"
       :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"
       :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
       @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
       :EntityDataRef="OperationalDB" :FeatureNavList="FeatureNavList"
       :EntityCollectionRef="EntityCollectionRef" :CurrentEntity="CurrentEntity"
       :DataSingleBuilder="true"
       :System="System" :SystemEntities="SystemEntities"  :AdditionalSaveMethod="''"
        @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
        :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit" :tab="tab"
        :DynamicWikiData="DynamicRecordData" :Business_Unit="Business_Unit"
        :RelatedObj="RelatedObj" :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
        :ActivitiesArray="ActivitiesArray" @CancelEditing="CancelEditing"
        :PrimaryFieldName="PrimaryFieldName"
       :ActiveEntityID="ConfigEntityID"/> 
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';
import NewLandingPage from '@/components/WebPages/NewLandingPage';
export default {
    props: ['System','SystemEntities','CurrentEntity','CanEdit','SampleOnly','RouteID','SystemPhotoLibraries','EntityCollectionRef'],
    components: {LandingPageSingle,NewLandingPage},
    data() {
        return {
            EditingSingleView: false,
            SingleBuildTabs: [],
            SubCollections: [],
            RecordData: {},
            RelatedObj: {},
            EntityReady: false,
        }
    },	
    computed:{
        ConfigEntityID(){
            return this.CurrentEntity.id
        },
        DynamicRecordData(){
      let vm = this
      let dataobj = {}
      //this should become OpsRecordID not routeparams, not work along with subcol entry view
      dataobj.id = this.RecordData.id
      //console.log('this.SystemEntities',this.SystemEntities)
      let entity = this.SystemEntities.find(obj => obj.id === this.ConfigEntityID)
      //console.log('entity',entity,this.EntityReady)
      
      if(entity && this.EntityReady){
      if(entity.SubCollections){
        dataobj.SubCollections = this.SubCollections
        this.SubCollections.map(subcol => {
          if(subcol.id === 'BookedUsers'){
            ///console.log('subcol',subcol)
            dataobj.BookedUsers = subcol.Data.map(bookeduser => {
              let usermatch = this.UsersArray.find(obj => obj.id === bookeduser.id)              
              //console.log('usermatch',usermatch)
              let userobj = Object.assign({},usermatch)
              userobj.BookingObj = Object.assign({},bookeduser)
              if(bookeduser.BookingDate){
               userobj.BookingDate = bookeduser.BookingDate 
              }
              else if(bookeduser.Created_On){
                userobj.BookingDate = bookeduser.Created_On 
              }              
              return userobj
            })
          }
        })
      }
      if(this.RelatedDataTabs){
        //this.EntityReady = false
        dataobj.RelatedTables = this.RecordData.RelatedTables
        //this.EntityReady = true
        //console.log('dataobj.RelatedTables',dataobj.RelatedTables)
      }
      //console.log('dataobj.RelatedTables',dataobj.RelatedTables,this.RelatedDataTabs,this.RecordData.RelatedTables)
      entity.AllFields.map(field => {
        //console.log('field.Name ',field.Name,this[field.Name])
        if(field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address'){
          field.FieldBreakdown.map(breakdown => {
            dataobj[breakdown.Name] = this.RecordData[breakdown.Name]
          })
        }
        else if(field.Type === 'Date' && this.RecordData[field.Name] && this.RecordData[field.Name].toDate){
          let dateobject = this.RecordData[field.Name].toDate()
          dataobj[field.Name] = format(dateobject,'yyyy-MM-dd')
          if(field.HasTimeInput){
            let hours = dateobject.getHours()
            let mins = dateobject.getMinutes()
            if(hours === 0){
              hours = '00'
            }
            if(mins === 0){
              mins = '00'
            }
            field.TimeResponse = hours+':'+mins
            dataobj[field.Name+'TimeResponse'] = field.TimeResponse
          }
        }
        else{
          if(this[field.Name]){
            dataobj[field.Name] = this[field.Name]
          }
          else if(typeof this.RecordData[field.Name] !== 'undefined'){
            dataobj[field.Name] = this.RecordData[field.Name]           
            if(field.Type === 'Option Set' && field.HasDeepHierarchy){
               let primopt = field.Options.find(obj => obj.ID === this.RecordData[field.Name].ID)
              if(primopt && field.Levels && field.Levels[1] && this.RecordData[field.Levels[1].FieldName]){   
                dataobj[field.Levels[1].FieldName] = this.RecordData[field.Levels[1].FieldName] 
                let secopt = primopt.Options.find(obj => obj.ID === this.RecordData[field.Levels[1].FieldName].ID)          
                if(secopt && field.Levels && field.Levels[2] && this.RecordData[field.Levels[2].FieldName]){
                  dataobj[field.Levels[2].FieldName] = this.RecordData[field.Levels[2].FieldName]
                  dataobj[field.Levels[2].FieldName].Options = secopt.Options
                  //console.log(field)
                }
              }
            }
          }
          
        }
        // if(this[field.Name]){
        //  dataobj[field.Name] = this[field.Name] 
        // }        
      })
      if(this.FeatureNavList){
        this.FeatureNavList.FeatureList.map(field => {
          if(typeof this.RecordData[field.Name] !== 'undefined'){
            dataobj[field.Name] = this.RecordData[field.Name]
          }
        })
      }
      
      //console.log('dataobj',entity,this.SubCollections,dataobj)
      if(this.RecordData.Order_Progress){
        dataobj.Order_Progress = this.RecordData.Order_Progress
      }
      return dataobj    
      }
      
    },
        
        ComputedSingleBuildTabs(){
            let AllFields = this.CurrentEntity.AllFields
      return this.SingleBuildTabs.map(tab => {
        let elementsprop = ''
        if(tab.Elements){
          elementsprop = 'Elements'
        }
        else{
          elementsprop = 'Sections'
        }
        //alert(elementsprop)
        if(tab[elementsprop]){
          tab[elementsprop] = tab[elementsprop].map(section => {
            //console.log(section)
          if(section.Fields){
            section.Fields = section.Fields.map(field => {
             
              let fieldobj = AllFields.find(obj => obj.id === field.Name)
              //console.log('AllFields,field.Name',AllFields,field.Name,fieldobj)
              if(fieldobj && fieldobj.Type === 'Lookup' && fieldobj.RelatedBuildID !== 'Users'){
                  //alert(field.Name+'Array')
                  //console.log('Lookup',fieldobj.RelatedRecord.split(' ').join('')+'Array',this.$store.state[fieldobj.RelatedRecord+'Array'],this.$store.state)
                  fieldobj.Array = this.$store.state[fieldobj.RelatedRecord.split(' ').join('')+'Array']
                  if(fieldobj.IsMultiple){
                    fieldobj.Array = fieldobj.Array.map(item => {                      
                            let itemobj = {
                            [fieldobj.LookupFieldName]: item[fieldobj.LookupFieldName],
                            id: item.id
                            }
                            return itemobj
                        })
                    }
                }
                else if(fieldobj && fieldobj.Type === 'Lookup' && fieldobj.RelatedBuildID === 'Users'){
                  //alert(field.Name+'Array')
                  //console.log('Lookup','Users',this.$store.state['Users'],this.$store.state)
                  fieldobj.Array = this.$store.state['Users']
                }
                if(!fieldobj){
                  console.log('missing field ',field)
                  fieldobj = field
                }
              return fieldobj
            })
          }
          //prepare updated permissionname Props just prior to GetCabinetDocuments
          //CustomRolesDefault
          //console.log(section.Name,section.DataViewName,section,this.$store.state)
          if(section.DataViewName === 'Data Single Document Register' || section.DataViewName === 'Data Single Gallery'){
          //  this.CustomRolesDefault.map(defrole => {
          //    console.log(defrole.Prop,section.Name,section[defrole.Prop])
          //  })
            if(section.CreatePermission === 'Inherited'){
              section.CanCreate = this.CanEdit
            }
            else if(section.CreatePermission === 'Custom Role'){
              section.CustomCreateRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanCreate = true
                }
              })
            }
            else if(section.CreatePermission === 'Admin'){
              section.CanCreate = this.userIsAdmin
            }
            if(section.GetPermission === 'Inherited'){
              section.CanGet = true
            }
            else if(section.GetPermission === 'Custom Role'){
              section.CustomGetRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanGet = true
                }
              })
            }
            else if(section.GetPermission === 'Admin'){
              section.CanGet = this.userIsAdmin
            }
            if(section.ListPermission === 'Inherited'){
              section.CanList = true
            }
            else if(section.ListPermission === 'Custom Role'){
              section.CustomListRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanList = true
                }
              })
            }
            else if(section.ListPermission === 'Admin'){
              section.CanList = this.userIsAdmin
            }
            if(section.UpdatePermission === 'Inherited'){
              section.CanEdit = this.CanEdit
            }
            else if(section.UpdatePermission === 'Custom Role'){
              section.CustomUpdateRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanEdit = true
                }
              })
            }
            else if(section.UpdatePermission === 'Admin'){
              section.CanEdit = this.userIsAdmin
            }
            if(section.DeletePermission === 'Inherited'){
              if(this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Custom Roles'){
                section.CanDelete = this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'CanDelete']
              }
              else if(this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Business Unit'){
               if(this.deleteAll){
                  section.CanCreate =  true
                }
                else if(this.deleteUnitdown){
                   if(this.userBUChildren.includes(this.DynamicRecordData.Business_Unitid)){
                    section.CanCreate = true  
                    }
                }
                else if(this.deleteUserUnit){
                  if(this.DynamicRecordData.Business_Unitid === this.userBUID){
                    section.CanCreate = true  
                    }
                }
                else if(this.deleteOwner){
                  if(this.DynamicRecordData.Ownerid === this.UserRecord.id){
                    section.CanCreate = true  
                    }
                }
              }
            }
            else if(section.DeletePermission === 'Custom Role'){
              section.CustomDeleteRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanDelete = true
                }
              })
            }
            else if(section.DeletePermission === 'Admin'){
              section.CanDelete = this.userIsAdmin
            }
          }
          else if(section.DataViewName === 'Data Single SubCollection'){
            //console.log(section)
            let subcol = this.SubCollections.find(obj => obj.id === section.Entityid)
            section.CanCreate = this.CanEdit
            section.CanDelete = this.CanDelete
            section.CanEdit = this.CanEdit

          }
          // if(this.SelectedElement && this.SelectedElement.DisplayName !== section.DisplayName){
          //   section.ShowComponents = false
          // }
          return section
        })
        // .filter(elmnt => {
        //   return elmnt.ShowComponents
        // })
        }
         //tab.ShowComponents = false
        return tab
      }).filter(tab => {
        return tab.Elements.length > 0
      })
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        CurrentEntity: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue && newvalue){ 
                    this.PrepRender()
              }
            },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.PrepRender()
    },
    methods:{
        PrepRender(){
           //alert('PrepRender')
            this.SingleBuildTabs = this.CurrentEntity.SingleBuildTabs ? this.CurrentEntity.SingleBuildTabs : []
                    this.SubCollections = this.CurrentEntity.SubCollections ? this.CurrentEntity.SubCollections : []
                    this.StatusField = this.CurrentEntity.AllFields.find(obj => obj.id === 'Status')
            let Status = this.StatusField.DefaultOption
            let StatusReason = this.StatusField.DefaultLevel2Option
            if(this.SampleOnly){
            this.RecordData = {
                Created_On: new Date(),   
                Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                Created_Byid: this.userLoggedIn.id,
                Modified_On: new Date(),   
                Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                Modified_Byid: this.userLoggedIn.id,
                Business_Unit: {id: 'Head_Office',Name: 'Head Office'},
                Business_Unitid: 'Head_Office',
                Owner: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                Ownerid: this.userLoggedIn.id,
                Status: Status,
                Status_Reason: StatusReason,
                id: process.env.VUE_APP_RA_SYSTEM_ID
            }
            this.RelatedObj = {id: this.RouteID, [this.CurrentEntity.Primary_Field_Name]: this.RecordData[this.CurrentEntity.Primary_Field_Name],identifier: this.CurrentEntity.Primary_Field_Name,collection: this.CurrentEntity.id.split('_').join('').toLowerCase(),Link: this.CurrentEntity.SingleName.split(' ').join('') }
            this.EntityReady = true
            //console.log('this.EntityReady',this.EntityReady)
            }
            
        }
    }
}
</script>

<style>

</style>



